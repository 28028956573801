import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider, CssBaseline, createTheme } from '@mui/material'

import { store } from './app/store'
import { App } from './App'
import { reportWebVitals } from './reportWebVitals'
import './scss/index.scss'

const container = document.getElementById('root')!
const root = createRoot(container)

const theme = createTheme({
    typography: {
        fontFamily: 'Overpass',
    },
})

root.render(
    <StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Provider store={store}>
                <App />
            </Provider>
        </ThemeProvider>
    </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
